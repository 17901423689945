<template>
  <v-container>
    <v-row>
      <v-col cols="12"
             md="11">
        <h1>Users Department</h1>
      </v-col>
      <v-col cols="12"
             md="1">
        <v-menu
          bottom
          left>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list rounded>
            <v-subheader>Menu</v-subheader>
            <v-list-item-group>
              <v-list-item
                v-for="(item, i) in submenu"
                :key="i"
                dense
                @click="openFormDepartment = true">
                <v-list-item-icon>
                  <v-icon v-text="item.icon" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text" />
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <department-list :departments="departmentsList.departments" />
    <v-dialog
      v-model="openFormDepartment"
      persistent
      max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">Create Department</span>
        </v-card-title>
        <v-divider />
        <v-card-text class="card-text">
          <v-container>
            <v-text-field
              v-model="department.name"
              label="Department name"
              outlined
              required />

            <v-checkbox
              v-model="department.activated"
              class="checkboxMarginSet"
              label="Active" />
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            depressed
            color="accent"
            @click.prevent="create">
            Create
          </v-btn>
          <v-btn class="ml-5"
                 depressed
                 color="error"
                 @click="openFormDepartment = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Departments',
  components: {
    DepartmentList: () => import('@/components/Department/departmentList.vue'),
  },
  data() {
    return {
      submenu: [
        { text: 'Create Department', icon: 'mdi-folder-account-outline' },
      ],
      openFormDepartment: false,
      openFormUpdateDepartment: false,
      department: {
        name: '',
        activated: true,
      }
    };
  },

  async created() {
    await this.$store.dispatch('department/getAllDepartments');
  },

  computed: {
    ...mapGetters({
      departmentsList: 'department/GET_DEPARTMENTS'
    }),

    // departmentData() {
    //   return this.departmentsList.departments
    //     .map((item, index) => ({
    //       No: index + 1,
    //       ...item,
    //       style: {
    //         color: item.activated === true ? '#00C853' : '#C62828'
    //       }
    //     }));
    // },
  },

  methods: {
    async create() {
      await this.$store.dispatch('department/createDepartment', this.department);

      this.department.name = '';
      this.department.activated = true;

      this.openFormDepartment = false;
    }
  }
};
</script>

<style>
</style>
